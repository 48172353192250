










import { Vue, Component, Prop, Emit } from 'nuxt-property-decorator'
import styled from 'vue-styled-components'
import logo from '~/assets/logo.svg'
import adminRibbon from '~/assets/adminRibbon.svg'
import { SelectedItem } from '~/components/NavbarSelectedItem'

const Wrapper = styled.div`
  height: 64px;
  background-color: #c39143;
  box-shadow: 0 2px 8px 0 rgba(0, 0, 0, 0.2);
  display: flex;
  align-items: center;
  color: #fff;
  padding: 0 20px;
  margin-bottom: 10px;
  position: sticky;
  top: 0px;
  z-index: 1900;
`

const NavItem = styled.div`
  cursor: pointer;
  padding: 3px 16px;
  font-weight: bold;
  border-radius: 16px;
  img {
    margin-right: 8px;
    width: 20px;
    height: 20px;
    object-fit: contain;
  }
  &.right {
    margin-left: auto;
  }
  &:hover {
    background-color: rgba(255, 255, 255, 0.2);
  }
  &.selected {
    background-color: rgba(255, 255, 255, 0.2);
  }
  &.no-hover {
    &:hover {
      background-color: inherit;
    }
  }
  transition: all 0.3s ease 0s;
`

const Logo = styled.div`
  cursor: pointer;
  background: url(${logo}) center / contain no-repeat;
  width: 108px;
  height: 40px;
  margin: 0 30px;
`

const AdminRibbon = styled.div`
  cursor: pointer;
  background: url(${adminRibbon}) center / contain no-repeat;
  width: 108px;
  height: 40px;
  margin-left: -18px;
  margin-top: 18px;
`

@Component({
  components: {
    Wrapper,
    NavItem,
    Logo,
    AdminRibbon
  }
})
export default class Navbar extends Vue {
  @Prop(String) readonly name?: string;
  @Prop({ type: String, default: 'top' }) readonly selectedItem!: SelectedItem;
  visible = false;

  @Emit()
  logout (): void {} // eslint-disable-line @typescript-eslint/no-empty-function
}
