import Vue from 'vue'

// eslint-disable-next-line @typescript-eslint/no-inferrable-types
Vue.filter('truncate', function (value: string | null, limit: number, omission: string = '…'): string | null {
  if (!value) { return value }

  if (value.length > limit) {
    return `${value.substring(0, limit)}${omission}`
  } else {
    return value
  }
})
