






























import { Vue, Component, Emit } from 'nuxt-property-decorator'
import styled from 'vue-styled-components'
import logo from '~/assets/logo.svg'
import userIcon from '~/assets/user.svg'
import HeaderPopover from '~/components/HeaderPopover.vue'
import CsLink from '~/components/CsLink.vue'
import { unifaPolicyUrl, termsOfServiceUrl } from '~/helpers/constUrls'

const Wrapper = styled.div`
  height: 64px;
  background-color: #c39143;
  box-shadow: 0 2px 8px 0 rgba(0, 0, 0, 0.2);
  display: flex;
  align-items: center;
  justify-content: space-between;
  color: #fff;
  padding: 0 20px;
  margin-bottom: 10px;
  position: sticky;
  top: 0px;
  z-index: 1900;
`

const TextBlock = styled.div`
  height: 64px;
  display: flex;
  align-items: center;
  padding-left: 20px;
`

const NavItem = styled.div`
  cursor: pointer;
  padding: 3px 16px;
  font-weight: bold;
  border-radius: 16px;
  img {
    margin-right: 8px;
    width: 20px;
    height: 20px;
    object-fit: contain;
  }
  &.right {
    margin-left: auto;
  }
  &.profile {
    padding: 0;
    display: flex;
    align-items: center;
    cursor: pointer;
    width: 240px;
    > .name {
      margin-right: 5px;
      white-space: nowrap;
    }
    .menu-trigger {
      width: 100%;
      text-align: right;
    }
  }
  &:hover {
    background-color: rgba(255, 255, 255, 0.2);
  }
  &.selected {
    background-color: rgba(255, 255, 255, 0.2);
  }
  &.no-hover {
    &:hover {
      background-color: inherit;
    }
  }
  transition: all 0.3s ease 0s;
`

const Logo = styled.div`
  cursor: pointer;
  background: url(${logo}) center / contain no-repeat;
  width: 108px;
  height: 40px;
  margin: 0 30px;
`

const UserIcon = styled.div`
  background: url(${userIcon}) center / contain no-repeat;
  width: 40px;
  height: 40px;
  margin-right: 5px;
`

@Component({
  components: {
    Wrapper,
    HeaderPopover,
    TextBlock,
    NavItem,
    Logo,
    UserIcon,
    CsLink
  }
})
export default class NavbarForGuest extends Vue {
  visible = false;
  policyUrl = unifaPolicyUrl;
  termsOfServiceUrl = termsOfServiceUrl;

  toggele (): void {
    this.visible = !this.visible
  }

  @Emit()
  logout (): void {} // eslint-disable-line @typescript-eslint/no-empty-function
}
