







import { Vue, Component } from 'nuxt-property-decorator'
import { destroyApiWebUserSessionPath } from '~/rails/routes'
import NavbarForGuest from '~/components/NavbarForGuest.vue'

@Component({
  components: {
    NavbarForGuest
  }
})
export default class GuestLayout extends Vue {
  async logout (): Promise<void> {
    // TODO: 検証用のため確認ダイアログのデザイン未実装
    const res = await this.$axios.delete(destroyApiWebUserSessionPath())
    if (res.status === 200) {
      this.$router.push('/guest_login')
    } else {
      this.$message({
        message: 'ログアウトに失敗しました',
        type: 'error'
      })
    }
  }
}
