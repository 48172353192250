/* eslint-disable @typescript-eslint/no-explicit-any */
import html2canvas from 'html2canvas'
import JsPdf from 'jspdf'
import { NuxtContext } from '~/types/nuxt'

interface IPdfOption {
  orientation?: 'p' | 'portrait' | 'l' | 'landscape';
  unit?: 'pt' | 'mm' | 'cm' | 'in';
  format?: 'a3' | 'a4' | 'a5' | 'letter' | 'legal';
  monochrome?: boolean;
  oneSheet?: boolean;
}

interface IPdf {
  oneSheetHeight(selector: string, options?: IPdfOption): number;
  save(selector: string, fileName: string, options?: IPdfOption): void;
}

const DEFAULT_OPTIONS: IPdfOption = {
  orientation: 'p',
  unit: 'pt',
  format: 'a4',
  monochrome: false,
  oneSheet: false
}

const CANVAS_IMAGE_FORMAT = 'JPEG'

export class PdfPlugin implements IPdf {
  oneSheetHeight (selector: string, options: IPdfOption = {}): number {
    const element = document.querySelector(selector)
    if (!element) {
      return 0
    }
    const op = Object.assign(DEFAULT_OPTIONS, options || {})
    const doc = new JsPdf(op.orientation, op.unit, op.format)
    const pageScale = element.clientWidth / doc.internal.pageSize.width
    return doc.internal.pageSize.height * pageScale
  }

  // 対象のDOMを画像化し、PDFに貼り付けて保存する一貫処理
  // 呼び出し元が細かく操作する必要が出たら new JsPdf() のインスタンスをreturnする処理を作成する
  async save (selector: string, fileName: string, options: IPdfOption = {}): Promise<void> {
    const element = document.querySelector(selector)
    if (!element) {
      return Promise.reject(new Error(`DOM not found.selector: ${selector}`))
    }

    const op = Object.assign(DEFAULT_OPTIONS, options || {})
    const doc = new JsPdf(op.orientation, op.unit, op.format)
    const canvas = await html2canvas(element as HTMLElement, { logging: process.env.NODE_ENV !== 'production' })
    // モノクロ対応
    if (op.monochrome) {
      const context = canvas.getContext('2d')
      if (context) {
        const pixels = context.getImageData(0, 0, canvas.width, canvas.height)
        for (let y = 0; y < pixels.height; y++) {
          for (let x = 0; x < pixels.width; x++) {
            const i = y * 4 * pixels.width + x * 4
            const pix = Number(pixels.data[i] + pixels.data[i + 1] + pixels.data[i + 2])
            const rgb = parseInt(String(pix / 3), 10)
            pixels.data[i] = rgb
            pixels.data[i + 1] = rgb
            pixels.data[i + 2] = rgb
          }
        }
        context.putImageData(pixels, 0, 0, 0, 0, canvas.width, canvas.height)
      }
    }
    const imageData: any = canvas.toDataURL('image/jpeg')

    // NOTE: 1ページに納めて印刷
    if (op.oneSheet) {
      const heightScale = canvas.height / doc.internal.pageSize.height
      const widthScale = canvas.width / doc.internal.pageSize.width
      // NOTE: 縦横比どちらに合わせるか判定
      if (heightScale < widthScale) {
        doc.addImage(imageData, CANVAS_IMAGE_FORMAT, 0, 0, doc.internal.pageSize.width, 0)
      } else {
        doc.addImage(imageData, CANVAS_IMAGE_FORMAT, 0, 0, 0, doc.internal.pageSize.height)
      }
    } else {
      // NOTE: 横幅から縮尺を算出
      const pageScale = canvas.width / doc.internal.pageSize.width
      // NOTE: 描画時の画像の高さを算出
      const canvasHeight = canvas.height / pageScale
      // NOTE: 描画時の画像の高さとページの高さからページ数を算出
      const totalPageCount = Math.ceil(canvasHeight / doc.internal.pageSize.height);

      // NOTE: ページが末尾に差し込まれるわけではないので、先にページ数分挿し込み
      [...Array(totalPageCount - 1)].forEach(() => {
        doc.addPage()
      });
      [...Array(totalPageCount)].forEach((_, i) => {
        doc.setPage(i + 1)
        // ページ数分座標をずらして描画する
        doc.addImage(
          imageData,
          CANVAS_IMAGE_FORMAT,
          0,
          doc.internal.pageSize.height * (-1 * i),
          doc.internal.pageSize.width,
          0
        )
      })
    }
    doc.save(`${fileName}.pdf`)
  }
}

export default (ctx: NuxtContext, inject: any): void => {
  ctx.$pdf = new PdfPlugin()
  inject('pdf', new PdfPlugin())
}
