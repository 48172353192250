










import { Vue, Component, Prop } from 'vue-property-decorator'
import styled from 'vue-styled-components'

const Wrapper = styled.div`
  .el-popover {
    color: #c39143;
    margin-top: 25px;
    padding: 0;
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
    border-radius: 10px;
  hr {
    width: 100%;
    height: 2px;
    background-color: #dddddd;
    display: block;
    border: 0;
  }
`

@Component({
  components: {
    Wrapper
  }
})
export default class HeaderPopover extends Vue {
  @Prop(Boolean) readonly visible = false;
}
