











import { Vue, Component } from 'nuxt-property-decorator'
import styled from 'vue-styled-components'
import lottie, { AnimationItem } from 'lottie-web'

import animationData from './AutoShiftLoadingAnimationData.json'

const Wrapper = styled.div`
  z-index: 100;
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background: rgba(255, 255, 255, 0.8);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  color: #956134;
  .auto-shift-loading-icon {
    width: 240px;
    height: 240px;
  }
  .auto-shift-loading-title {
    font-size: 22px;
    font-weight: bold;
  }
  .auto-shift-loading-explanation {
    magin-top: 10px;
    font-size: 18px;
    text-align: center;
  }
`

@Component({
  components: { Wrapper }
})
export default class AutoShiftLoading extends Vue {
  anim: AnimationItem | null = null;

  mounted (): void {
    this.anim = lottie.loadAnimation({
      container: this.$refs.autoShiftLoading as Element,
      renderer: 'svg',
      loop: true,
      autoplay: true,
      animationData
    })
  }
}
