import { NuxtContext } from '~/types/nuxt'
import checkUpdate from '~/middleware/checkUpdate'

export default function (context: NuxtContext): void {
  const { $axios, app, redirect, store, rollbar, route, rollbarError } = context
  $axios.onRequest((config) => {
    config.headers.client = app.$cookies.get('authClient')
    config.headers.uid = app.$cookies.get('authUId')
    config.headers['access-token'] = app.$cookies.get('authAccessToken')
  })

  $axios.onError(async (error) => {
    const code = Number(error.response?.status)
    try {
      await checkUpdate()
    } catch (e) {
      // さらにエラーが発生すると困るので握りつぶす
      console.error(e)
      rollbarError(e)
    }

    if (code === 503) {
      // 最適実行中のシフトがある場合は画面ロック
      if (error.response?.data?.error === 'ProcessingAutoShiftExistsError') {
        if (!store.state.autoShift.processsing) {
          store.commit('autoShift/setProcessing', true)
          store.dispatch('autoShift/startPolling', { axios: $axios, rollbar, route, redirect })
        }
      }
    }
    if (code === 403 || code === 401) {
      // NOTE: 管理画面ログインで失敗した時に管理画面ログインにリダイレクトさせたい
      if (['/admin', '/admin_login'].includes(route.path)) {
        redirect('/admin_login')
      } else {
        // 前回ログイン時のroleが取得できる場合は対象ログイン画面にredirect
        switch (app.$cookies.get('userRole')) {
        case 'admin':
          redirect('/admin_login')
          break
        case 'guest':
          redirect('/guest_login')
          break
        default:
          redirect('/login')
          break
        }
      }
    }
    throw error
  })
}
