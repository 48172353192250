



























































import { Vue, Component, Prop, Emit } from 'nuxt-property-decorator'
import styled from 'vue-styled-components'
import logo from '~/assets/logo.svg'
import userIcon from '~/assets/user.svg'
import HeaderPopover from '~/components/HeaderPopover.vue'
import { SelectedItem } from '~/components/NavbarSelectedItem'
import CsLink from '~/components/CsLink.vue'
import Balloon from '~/components/Balloon'
import { unifaPolicyUrl, termsOfServiceUrl } from '~/helpers/constUrls'

const Wrapper = styled.div`
  height: 64px;
  background-color: #c39143;
  box-shadow: 0 2px 8px 0 rgba(0, 0, 0, 0.2);
  display: flex;
  align-items: center;
  color: #fff;
  padding: 0 20px;
  margin-bottom: 10px;
  position: sticky;
  top: 0px;
  z-index: 1900;
`

const TextBlock = styled.div`
  height: 64px;
  display: flex;
  align-items: center;
  padding-left: 20px;
`

const NavItem = styled.div`
  cursor: pointer;
  padding: 3px 16px;
  font-weight: bold;
  border-radius: 16px;
  white-space: nowrap;
  img {
    margin-right: 8px;
    width: 20px;
    height: 20px;
    object-fit: contain;
  }
  &.right {
    margin-left: auto;
  }
  &.profile {
    padding: 0;
    display: flex;
    align-items: center;
    cursor: pointer;
    width: 240px;
    > .name {
      margin-right: 5px;
      white-space: nowrap;
    }
  }
  &:hover {
    background-color: rgba(255, 255, 255, 0.2);
  }
  &.selected {
    background-color: rgba(255, 255, 255, 0.2);
  }
  &.no-hover {
    &:hover {
      background-color: inherit;
    }
  }
  transition: all 0.3s ease 0s;
`

const Logo = styled.div`
  cursor: pointer;
  background: url(${logo}) center / contain no-repeat;
  width: 108px;
  height: 40px;
  margin: 0 30px;
`

const UserIcon = styled.div`
  background: url(${userIcon}) center / contain no-repeat;
  width: 40px;
  height: 40px;
  margin-right: 5px;
`

const Boundary = styled.div`
  width: 2px;
  height: 32px;
  background-color: rgba(255, 255, 255, 0.2);
  margin-right 20px;
`

const ShiftDisabledLink = styled.span`
  cursor: not-allowed;
  position: relative;
  .balloon {
    position: absolute;
    top: 40px;
    right: -100px;
    text-align: center;
    z-index: 100;
    display: none;
  }
  &:hover .balloon {
    display: block;
  }
`

@Component({
  components: {
    Wrapper,
    HeaderPopover,
    TextBlock,
    NavItem,
    Logo,
    UserIcon,
    Boundary,
    CsLink,
    Balloon,
    ShiftDisabledLink
  }
})
export default class Navbar extends Vue {
  @Prop(String) readonly name?: string;
  @Prop({ type: String, default: 'top' }) readonly selectedItem!: SelectedItem;
  visible = false;
  policyUrl = unifaPolicyUrl;
  termsOfServiceUrl = termsOfServiceUrl;

  get isTop (): boolean {
    return this.selectedItem === 'top'
  }

  get isShift (): boolean {
    return this.selectedItem === 'shift'
  }

  get isWork (): boolean {
    return this.selectedItem === 'work'
  }

  get isSetting (): boolean {
    return this.selectedItem === 'setting'
  }

  linkTo (link: string): void {
    this.$router.push(link)
  }

  openKidslyLink (): void {
    window.open(process.env.kidslyHost, '_blank')
  }

  toggele (): void {
    this.visible = !this.visible
  }

  alert (message: string): void {
    alert(message)
  }

  @Emit()
  logout (): void {} // eslint-disable-line @typescript-eslint/no-empty-function
}
