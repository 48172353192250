import { NuxtContext } from '~/types/nuxt'

export default (context: NuxtContext): void => {
  const { app, redirect, store, $axios, rollbar, route } = context
  const authClient = app.$cookies.get('authClient')
  const authUId = app.$cookies.get('authUId')
  const authAccessToken = app.$cookies.get('authAccessToken')
  if (!authClient || !authUId || !authAccessToken) {
    redirect('/login')
    return
  }
  // アクセスできる状態かどうかを調べる
  store.dispatch('autoShift/startPolling', { axios: $axios, rollbar, route, redirect })
}
