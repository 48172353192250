interface IMeState {
  id?: number;
  name?: string;
  isBeta?: boolean;
  isReferenceRequired: boolean;
}

interface IMeMutation {
  setId: (state: IMeState, id: number) => void;
  setName: (state: IMeState, text: string) => void;
  setIsBeta: (state: IMeState, isBeta: boolean) => void;
  setIsReferenceRequired: (state: IMeState, isReferenceRequired: boolean) => void;
}

export const state: IMeState = {
  name: undefined,
  isBeta: false,
  isReferenceRequired: false
}

export const mutations: IMeMutation = {
  setId (state: IMeState, id: number): void {
    state.id = id
  },
  setName (state: IMeState, text: string): void {
    state.name = text
  },
  setIsBeta (state: IMeState, isBeta: boolean): void {
    state.isBeta = isBeta
  },
  setIsReferenceRequired (state: IMeState, isReferenceRequired: boolean): void {
    state.isReferenceRequired = isReferenceRequired
  }
}
