import styled from 'vue-styled-components'

const Balloon = styled.div`
  padding: 15px;
  width: 300px;
  border-radius: 8px;
  background-color: #fff;
  color: #222;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.2);
  position: relative;
  &.animated {
    animation-duration: 1.8s;
    animation-fill-mode: both;
    animation-timing-function: ease-out;
    animation-iteration-count: infinite;
    animation-name: balloon;
  }
  &:before {
    content: '';
    position: absolute;
    display: block;
    width: 15px;
    height: 15px;
    left: -5px;
    top: 20px;
    background: #fff;
    transform: rotate(45deg);
    box-shadow: -1px 1px 2px 0 rgba(0, 0, 0, 0.1);
  }
  &.top:before {
    content: '';
    position: absolute;
    display: block;
    width: 15px;
    height: 15px;
    left: 142px;
    top: -8px;
    background: #fff;
    transform: rotate(45deg);
    box-shadow: -1px -1px 2px 0 rgba(0, 0, 0, 0.1);
  }
  @keyframes balloon {
    0% {
      transform: scale(1);
    }
    50% {
      transform: scale(1.1);
    }
    100% {
      transform: scale(1);
    }
  }
`

export default Balloon
