import { render, staticRenderFns } from "./guest.vue?vue&type=template&id=482c2234&scoped=true&lang=pug&"
import script from "./guest.vue?vue&type=script&lang=ts&"
export * from "./guest.vue?vue&type=script&lang=ts&"
import style0 from "./guest.vue?vue&type=style&index=0&id=482c2234&lang=sass&scoped=true&"


/* normalize component */
import normalizer from "!../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "482c2234",
  null
  
)

export default component.exports