/* eslint-disable @typescript-eslint/no-explicit-any */
import Vue from 'vue'
import IntroJs from 'intro.js'
import { NuxtContext } from '~/types/nuxt'

// 必要人数設定（setChildminderNumber）のintroはないが、完了しないと進めないのでintroの一部として管理する
type IntroKey =
  | 'addShiftManagement'
  | 'setChildminderNumber'
  | 'addShift'
  | 'showStatistics'
  | 'switchStatistics'
  | 'refStart'
  | 'showOneDayShift';
type IntroStatus = { [prop in IntroKey]: boolean };
interface IIntroStep {
  element?: string;
  intro?: string;
  position?: 'top' | 'right' | 'bottom' | 'left';
}

interface IIntroCallbackOption {
  onComplete?: (intro: any) => void;
  onExit?: (intro: any) => void;
  onBeforeExit?: (intro: any) => void;
  onChange?: (targetElement: Element, intro: any) => void;
  onBeforeChange?: (targetElement: Element, intro: any) => void;
  onAfterChange?: (targetElement: Element, intro: any) => void;
}

interface IIntro {
  start(key: IntroKey, options: { steps: IIntroStep[] }, callbackOption?: IIntroCallbackOption): any;
  finish(key: IntroKey): void;
  finished(key: IntroKey): boolean;
  again(key: IntroKey): void;
  canStart(key: IntroKey): boolean;
}

const INTRO_STORAGE_KEY = 'lookmeeshiftIntro'
// 新たにintro群を追加する場合は、keyの配列を追加する
const INTRO_ORDER_GROUP: Array<Array<IntroKey>> = [
  ['addShiftManagement', 'setChildminderNumber', 'addShift', 'showStatistics', 'switchStatistics', 'showOneDayShift'],
  ['refStart']
]
const DEFAULT_OPTIONS = {
  showBullets: false,
  showStepNumbers: false,
  exitOnEsc: false,
  exitOnOverlayClick: false,
  prevLabel: '戻る',
  nextLabel: '次へ',
  doneLabel: 'OK',
  scrollToElement: false
}

export class IntroPlugin implements IIntro {
  private intro: any = IntroJs();

  // intro.jsがbody直下にinsertする、対象elementを強調するための枠
  private get introFixedTooltip (): Element {
    return document.getElementsByClassName('introjs-fixedTooltip')[0]
  }

  // intro.jsがbody直下にinsertするoverlay
  private get introOverlay (): Element {
    return document.getElementsByClassName('introjs-overlay')[0]
  }

  // intro対象elementの先祖要素の中でposition:fixedのもの
  private get introFixedParent (): HTMLElement {
    return document.getElementsByClassName('introjs-fixParent')[0] as HTMLElement
  }

  // 参考
  // https://introjs.com/docs/intro/options/
  start (key: IntroKey, options: { steps: IIntroStep[] }, callbackOption: IIntroCallbackOption = {}): any {
    if (!this.canStart(key)) {
      return
    }

    this.intro.exit(true)
    this.intro = IntroJs().setOptions(Object.assign({}, DEFAULT_OPTIONS, options))
    this.intro.oncomplete(() => {
      if (callbackOption.onComplete) {
        return callbackOption.onComplete(this.intro)
      }
    })
    this.intro.onexit(() => {
      if (callbackOption.onExit) {
        return callbackOption.onExit(this.intro)
      }
    })
    this.intro.onbeforeexit(() => {
      if (callbackOption.onBeforeExit) {
        return callbackOption.onBeforeExit(this.intro)
      }
    })
    this.intro.onchange((targetElement: Element) => {
      if (callbackOption.onChange) {
        callbackOption.onChange(targetElement, this.intro)
      }
    })
    this.intro.onbeforechange((targetElement: Element) => {
      // intro対象のelementが"position: fixed;"を持つ要素の子孫の場合まともに表示されないため、DOM操作で無理やりカバー
      if (this.introFixedTooltip) {
        this.introFixedTooltip.classList.remove('introjs-fixedTooltip-outline')
      }
      // vueの描画タイミングとスクロール量でtop, heightがずれてしまうので、必ずトップに。
      // ※ 画面下部にある要素にはイントロを表示できない恐れあり。これをはずしてもまともに動きません。
      window.scrollTo({ top: 0 })

      // option
      if (callbackOption.onBeforeChange) {
        return callbackOption.onBeforeChange(targetElement, this.intro)
      }
    })
    this.intro.onafterchange((targetElement: Element) => {
      // intro対象のelementが"position: fixed;"を持つ要素の子孫の場合まともに表示されないため、DOM操作で無理やりカバー
      if (this.introFixedTooltip) {
        // アニメーション対応、overlay植え替え対応のため時間差で実行
        setTimeout(() => {
          const parent = targetElement.parentElement
          if (parent && this.introOverlay) {
            // body直下に作られるoverlayを対象elementの一つ前に挿入することで、対象elementのみ強調表示する
            parent.insertBefore(this.introOverlay, targetElement)
          }
          // 強調表示用の白枠をbackground: transparent, border: white にすることで対象elementを強調表示する
          // overlay同様insertすると表示がバグるためこのような対応をとっています。
          if (this.introFixedTooltip) {
            this.introFixedTooltip.classList.add('introjs-fixedTooltip-outline')
          }
        }, 300)
      }

      // position: fixedのElementがいる場合intro.jsがclass付与でz-indexを上書きしてしまうので、それをさらに上書きする。
      // element-uiのダイアログのz-indexが表示するたびに変動するため、その時のz-indexをcssTextに追加する。
      if (this.introFixedParent) {
        const zIndex = this.introFixedParent.style.zIndex
        if (zIndex) {
          this.introFixedParent.style.cssText = `${this.introFixedParent.style.cssText}z-index: ${zIndex} !important;`
        }
      }

      // option
      if (callbackOption.onAfterChange) {
        callbackOption.onAfterChange(targetElement, this.intro)
      }
    })
    return this.intro.start()
  }

  finish (key: IntroKey): void {
    // introが開始される状態じゃないのに誤って完了してしまわないように
    if (!this.canStart(key)) {
      return
    }
    this.setStatus(key, true)
  }

  finished (key: IntroKey): boolean {
    return this.introStatus[key]
  }

  again (key: IntroKey): void {
    this.setStatus(key, false)
  }

  canStart (key: IntroKey): boolean {
    if (this.finished(key)) {
      return false
    }
    // 対象のintroが所属するgroupから前提のintroのkeyを取得
    // それらが全て完了済であれば対象のintroに進めるものとする
    return INTRO_ORDER_GROUP.filter(introOrder => introOrder.includes(key)).some((introOrder) => {
      return introOrder.slice(0, introOrder.indexOf(key)).every(k => this.finished(k as IntroKey))
    })
  }

  private get introStatus (): IntroStatus {
    return JSON.parse(localStorage.getItem(INTRO_STORAGE_KEY) || '{}')
  }

  private setStatus (key: IntroKey, state: boolean): IIntro {
    const status = this.introStatus
    status[key] = state
    localStorage.setItem(INTRO_STORAGE_KEY, JSON.stringify(status))
    return this
  }
}

Vue.use(IntroJs)
export default (ctx: NuxtContext, inject: any): void => {
  ctx.$intro = new IntroPlugin()
  inject('intro', new IntroPlugin())
}
