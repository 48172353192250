






import { Vue, Component } from 'nuxt-property-decorator'
import styled from 'vue-styled-components'
import NavbarForAdmin from '~/components/NavbarForAdmin.vue'
import { destroyApiWebUserSessionPath } from '~/rails/routes'

const Wrapper = styled.div``

@Component({
  components: {
    Wrapper,
    NavbarForAdmin
  }
})
export default class AdminLayout extends Vue {
  async logout (): Promise<void> {
    const res = await this.$axios.delete(destroyApiWebUserSessionPath())
    if (res.status === 200) {
      this.$cookies.remove('authClient')
      this.$cookies.remove('authUId')
      this.$cookies.remove('authAccessToken')
      this.$cookies.remove('userRole')
      this.$router.push('/admin_login')
    } else {
      this.$message({
        message: 'ログアウトに失敗しました',
        type: 'error'
      })
    }
  }
}
