








import { Vue, Component, Prop } from 'nuxt-property-decorator'
interface ICsLinkProps {
  type: 'default' | 'disabled';
  textDecoration: 'underline' | 'none';
}

type CsLinkType = 'default' | 'disabled';
type TextDecoration = 'underline' | 'none';

@Component({})
export default class CsLink extends Vue {
  @Prop({ type: String, default: null }) readonly id!: string;
  @Prop({ type: String, default: 'default' }) readonly type!: CsLinkType;
  @Prop({ type: String, default: 'underline' }) readonly textDecoration!: TextDecoration;
  @Prop(String) readonly href?: string;
  @Prop({ type: Boolean, default: true }) readonly external!: boolean;
  @Prop(String) readonly rel?: string;
  @Prop(String) readonly target?: string;
}
