const middleware = {}

middleware['adminAuth'] = require('../middleware/adminAuth.ts')
middleware['adminAuth'] = middleware['adminAuth'].default || middleware['adminAuth']

middleware['auth'] = require('../middleware/auth.ts')
middleware['auth'] = middleware['auth'].default || middleware['auth']

middleware['authGuest'] = require('../middleware/authGuest.ts')
middleware['authGuest'] = middleware['authGuest'].default || middleware['authGuest']

middleware['checkUpdate'] = require('../middleware/checkUpdate.ts')
middleware['checkUpdate'] = middleware['checkUpdate'].default || middleware['checkUpdate']

export default middleware
