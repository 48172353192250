import { NuxtContext } from '~/types/nuxt'

export default ({ app, redirect }: NuxtContext): void => {
  const authClient = app.$cookies.get('authClient')
  const authUId = app.$cookies.get('authUId')
  const authAccessToken = app.$cookies.get('authAccessToken')
  const role = app.$cookies.get('userRole')
  if (!authClient || !authUId || !authAccessToken || role !== 'admin') {
    redirect('/admin_login')
  }
}
