import { Vue } from 'nuxt-property-decorator'
import Rollbar from 'rollbar'
import { NuxtContext } from '~/types/nuxt'

function rollbarError (this: Vue | NuxtContext, ...args: Rollbar.LogArgument[]): void {
  try {
    const isNuxtContext = (t: Vue | NuxtContext): t is NuxtContext => {
      return 'store' in t
    }
    const userId = isNuxtContext(this) ? this.store.state.me.id : this.$store.state.me.id
    this.rollbar?.configure({ payload: { person: { id: userId } } })
  } finally {
    this.rollbar?.error(...args)
  }
}

Vue.prototype.rollbarError = rollbarError
export default (ctx: NuxtContext): void => {
  ctx.rollbarError = rollbarError
}
