// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_GET_URL_IMPORT___ = require("../node_modules/css-loader/dist/runtime/getUrl.js");
var ___CSS_LOADER_URL_IMPORT_0___ = require("../assets/icons/new-tab.svg");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(false);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".cs-link._blank[data-v-7027c0fb]{background-image:url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ");background-repeat:no-repeat;background-position:100%;padding-right:25px}.cs-link[data-v-7027c0fb],.cs-link a[data-v-7027c0fb]{color:#ac712a;cursor:pointer;text-decoration:none}.cs-link[data-v-7027c0fb]:active,.cs-link[data-v-7027c0fb]:focus,.cs-link[data-v-7027c0fb]:hover,.cs-link[data-v-7027c0fb]:visited,.cs-link a[data-v-7027c0fb]:active,.cs-link a[data-v-7027c0fb]:focus,.cs-link a[data-v-7027c0fb]:hover,.cs-link a[data-v-7027c0fb]:visited{color:#ac712a}.cs-link.underline[data-v-7027c0fb],.cs-link.underline a[data-v-7027c0fb],.cs-link a.underline[data-v-7027c0fb],.cs-link a.underline a[data-v-7027c0fb]{text-decoration:underline}.cs-link.disabled[data-v-7027c0fb],.cs-link.disabled a[data-v-7027c0fb],.cs-link a.disabled[data-v-7027c0fb],.cs-link a.disabled a[data-v-7027c0fb]{color:#888;cursor:default}.cs-link.disabled[data-v-7027c0fb]:active,.cs-link.disabled[data-v-7027c0fb]:focus,.cs-link.disabled[data-v-7027c0fb]:hover,.cs-link.disabled[data-v-7027c0fb]:visited,.cs-link.disabled a[data-v-7027c0fb]:active,.cs-link.disabled a[data-v-7027c0fb]:focus,.cs-link.disabled a[data-v-7027c0fb]:hover,.cs-link.disabled a[data-v-7027c0fb]:visited,.cs-link a.disabled[data-v-7027c0fb]:active,.cs-link a.disabled[data-v-7027c0fb]:focus,.cs-link a.disabled[data-v-7027c0fb]:hover,.cs-link a.disabled[data-v-7027c0fb]:visited,.cs-link a.disabled a[data-v-7027c0fb]:active,.cs-link a.disabled a[data-v-7027c0fb]:focus,.cs-link a.disabled a[data-v-7027c0fb]:hover,.cs-link a.disabled a[data-v-7027c0fb]:visited{color:#888}", ""]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___;
