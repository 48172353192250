import Vue from 'vue'
import Router from 'vue-router'
import { normalizeURL, decode } from 'ufo'
import { interopDefault } from './utils'
import scrollBehavior from './router.scrollBehavior.js'

const _056ce1cc = () => interopDefault(import('../pages/admin/index.vue' /* webpackChunkName: "pages/admin/index" */))
const _d6932ffe = () => interopDefault(import('../pages/admin_login.vue' /* webpackChunkName: "pages/admin_login" */))
const _05c9bb4a = () => interopDefault(import('../pages/guest_login.vue' /* webpackChunkName: "pages/guest_login" */))
const _03f154f1 = () => interopDefault(import('../pages/login.vue' /* webpackChunkName: "pages/login" */))
const _2a54f534 = () => interopDefault(import('../pages/notices/index.vue' /* webpackChunkName: "pages/notices/index" */))
const _6c48d424 = () => interopDefault(import('../pages/admin/notice_categories/index.vue' /* webpackChunkName: "pages/admin/notice_categories/index" */))
const _63c422a6 = () => interopDefault(import('../pages/admin/notices/index.vue' /* webpackChunkName: "pages/admin/notices/index" */))
const _425c030d = () => interopDefault(import('../pages/admin/nurseries/index.vue' /* webpackChunkName: "pages/admin/nurseries/index" */))
const _b692aa8e = () => interopDefault(import('../pages/nursery/manual.vue' /* webpackChunkName: "pages/nursery/manual" */))
const _5c0febd9 = () => interopDefault(import('../pages/nursery/settings/index.vue' /* webpackChunkName: "pages/nursery/settings/index" */))
const _0261e1b3 = () => interopDefault(import('../pages/nursery/shift_managements/index.vue' /* webpackChunkName: "pages/nursery/shift_managements/index" */))
const _ce2e8a28 = () => interopDefault(import('../pages/nursery/support.vue' /* webpackChunkName: "pages/nursery/support" */))
const _2d1b85a2 = () => interopDefault(import('../pages/nursery/works/index.vue' /* webpackChunkName: "pages/nursery/works/index" */))
const _6d6fde88 = () => interopDefault(import('../pages/admin/notice_categories/new.vue' /* webpackChunkName: "pages/admin/notice_categories/new" */))
const _76dc6b74 = () => interopDefault(import('../pages/admin/notices/new.vue' /* webpackChunkName: "pages/admin/notices/new" */))
const _0d323355 = () => interopDefault(import('../pages/nursery/settings/assignable_shift.vue' /* webpackChunkName: "pages/nursery/settings/assignable_shift" */))
const _4289a9f6 = () => interopDefault(import('../pages/nursery/settings/auto_shift_target.vue' /* webpackChunkName: "pages/nursery/settings/auto_shift_target" */))
const _444ce9fa = () => interopDefault(import('../pages/nursery/settings/break_time_sequence.vue' /* webpackChunkName: "pages/nursery/settings/break_time_sequence" */))
const _17d2bbe2 = () => interopDefault(import('../pages/nursery/settings/childminder.vue' /* webpackChunkName: "pages/nursery/settings/childminder" */))
const _e3d2a41c = () => interopDefault(import('../pages/nursery/settings/childminder_combination.vue' /* webpackChunkName: "pages/nursery/settings/childminder_combination" */))
const _2d773f49 = () => interopDefault(import('../pages/nursery/settings/childminding_ratio.vue' /* webpackChunkName: "pages/nursery/settings/childminding_ratio" */))
const _5740fddf = () => interopDefault(import('../pages/nursery/settings/guest.vue' /* webpackChunkName: "pages/nursery/settings/guest" */))
const _cc1e4cf4 = () => interopDefault(import('../pages/nursery/settings/prohibited_shift_sequence.vue' /* webpackChunkName: "pages/nursery/settings/prohibited_shift_sequence" */))
const _11a5f8ea = () => interopDefault(import('../pages/nursery/settings/shift_fixed_assign_count_per_day.vue' /* webpackChunkName: "pages/nursery/settings/shift_fixed_assign_count_per_day" */))
const _224414fd = () => interopDefault(import('../pages/nursery/settings/shift_groups/index.vue' /* webpackChunkName: "pages/nursery/settings/shift_groups/index" */))
const _72be585a = () => interopDefault(import('../pages/nursery/settings/shift_pattern.vue' /* webpackChunkName: "pages/nursery/settings/shift_pattern" */))
const _97812ff4 = () => interopDefault(import('../pages/nursery/settings/template_shift.vue' /* webpackChunkName: "pages/nursery/settings/template_shift" */))
const _4c6a35d8 = () => interopDefault(import('../pages/nursery/works/log.vue' /* webpackChunkName: "pages/nursery/works/log" */))
const _d109d6c2 = () => interopDefault(import('../pages/nursery/settings/shift_groups/sort_childminder.vue' /* webpackChunkName: "pages/nursery/settings/shift_groups/sort_childminder" */))
const _5632cf78 = () => interopDefault(import('../pages/admin/nurseries/_id/index.vue' /* webpackChunkName: "pages/admin/nurseries/_id/index" */))
const _658629c4 = () => interopDefault(import('../pages/nursery/shift_managements/_id/index.vue' /* webpackChunkName: "pages/nursery/shift_managements/_id/index" */))
const _1fb2888a = () => interopDefault(import('../pages/nursery/works/_id.vue' /* webpackChunkName: "pages/nursery/works/_id" */))
const _491afa53 = () => interopDefault(import('../pages/admin/notice_categories/_id/edit.vue' /* webpackChunkName: "pages/admin/notice_categories/_id/edit" */))
const _73aadaca = () => interopDefault(import('../pages/admin/notices/_id/edit.vue' /* webpackChunkName: "pages/admin/notices/_id/edit" */))
const _7ec25561 = () => interopDefault(import('../pages/admin/nurseries/_id/request_auto_shifts/_request_auto_shift_id.vue' /* webpackChunkName: "pages/admin/nurseries/_id/request_auto_shifts/_request_auto_shift_id" */))
const _20b1a94e = () => interopDefault(import('../pages/notices/_id.vue' /* webpackChunkName: "pages/notices/_id" */))
const _3a49b1da = () => interopDefault(import('../pages/index.vue' /* webpackChunkName: "pages/index" */))

const emptyFn = () => {}

Vue.use(Router)

export const routerOptions = {
  mode: 'history',
  base: '/',
  linkActiveClass: 'nuxt-link-active',
  linkExactActiveClass: 'nuxt-link-exact-active',
  scrollBehavior,

  routes: [{
    path: "/admin",
    component: _056ce1cc,
    name: "admin"
  }, {
    path: "/admin_login",
    component: _d6932ffe,
    name: "admin_login"
  }, {
    path: "/guest_login",
    component: _05c9bb4a,
    name: "guest_login"
  }, {
    path: "/login",
    component: _03f154f1,
    name: "login"
  }, {
    path: "/notices",
    component: _2a54f534,
    name: "notices"
  }, {
    path: "/admin/notice_categories",
    component: _6c48d424,
    name: "admin-notice_categories"
  }, {
    path: "/admin/notices",
    component: _63c422a6,
    name: "admin-notices"
  }, {
    path: "/admin/nurseries",
    component: _425c030d,
    name: "admin-nurseries"
  }, {
    path: "/nursery/manual",
    component: _b692aa8e,
    name: "nursery-manual"
  }, {
    path: "/nursery/settings",
    component: _5c0febd9,
    name: "nursery-settings"
  }, {
    path: "/nursery/shift_managements",
    component: _0261e1b3,
    name: "nursery-shift_managements"
  }, {
    path: "/nursery/support",
    component: _ce2e8a28,
    name: "nursery-support"
  }, {
    path: "/nursery/works",
    component: _2d1b85a2,
    name: "nursery-works"
  }, {
    path: "/admin/notice_categories/new",
    component: _6d6fde88,
    name: "admin-notice_categories-new"
  }, {
    path: "/admin/notices/new",
    component: _76dc6b74,
    name: "admin-notices-new"
  }, {
    path: "/nursery/settings/assignable_shift",
    component: _0d323355,
    name: "nursery-settings-assignable_shift"
  }, {
    path: "/nursery/settings/auto_shift_target",
    component: _4289a9f6,
    name: "nursery-settings-auto_shift_target"
  }, {
    path: "/nursery/settings/break_time_sequence",
    component: _444ce9fa,
    name: "nursery-settings-break_time_sequence"
  }, {
    path: "/nursery/settings/childminder",
    component: _17d2bbe2,
    name: "nursery-settings-childminder"
  }, {
    path: "/nursery/settings/childminder_combination",
    component: _e3d2a41c,
    name: "nursery-settings-childminder_combination"
  }, {
    path: "/nursery/settings/childminding_ratio",
    component: _2d773f49,
    name: "nursery-settings-childminding_ratio"
  }, {
    path: "/nursery/settings/guest",
    component: _5740fddf,
    name: "nursery-settings-guest"
  }, {
    path: "/nursery/settings/prohibited_shift_sequence",
    component: _cc1e4cf4,
    name: "nursery-settings-prohibited_shift_sequence"
  }, {
    path: "/nursery/settings/shift_fixed_assign_count_per_day",
    component: _11a5f8ea,
    name: "nursery-settings-shift_fixed_assign_count_per_day"
  }, {
    path: "/nursery/settings/shift_groups",
    component: _224414fd,
    name: "nursery-settings-shift_groups"
  }, {
    path: "/nursery/settings/shift_pattern",
    component: _72be585a,
    name: "nursery-settings-shift_pattern"
  }, {
    path: "/nursery/settings/template_shift",
    component: _97812ff4,
    name: "nursery-settings-template_shift"
  }, {
    path: "/nursery/works/log",
    component: _4c6a35d8,
    name: "nursery-works-log"
  }, {
    path: "/nursery/settings/shift_groups/sort_childminder",
    component: _d109d6c2,
    name: "nursery-settings-shift_groups-sort_childminder"
  }, {
    path: "/admin/nurseries/:id",
    component: _5632cf78,
    name: "admin-nurseries-id"
  }, {
    path: "/nursery/shift_managements/:id",
    component: _658629c4,
    name: "nursery-shift_managements-id"
  }, {
    path: "/nursery/works/:id",
    component: _1fb2888a,
    name: "nursery-works-id"
  }, {
    path: "/admin/notice_categories/:id/edit",
    component: _491afa53,
    name: "admin-notice_categories-id-edit"
  }, {
    path: "/admin/notices/:id/edit",
    component: _73aadaca,
    name: "admin-notices-id-edit"
  }, {
    path: "/admin/nurseries/:id/request_auto_shifts/:request_auto_shift_id?",
    component: _7ec25561,
    name: "admin-nurseries-id-request_auto_shifts-request_auto_shift_id"
  }, {
    path: "/notices/:id",
    component: _20b1a94e,
    name: "notices-id"
  }, {
    path: "/",
    component: _3a49b1da,
    name: "index"
  }],

  fallback: false
}

export function createRouter (ssrContext, config) {
  const base = (config._app && config._app.basePath) || routerOptions.base
  const router = new Router({ ...routerOptions, base  })

  // TODO: remove in Nuxt 3
  const originalPush = router.push
  router.push = function push (location, onComplete = emptyFn, onAbort) {
    return originalPush.call(this, location, onComplete, onAbort)
  }

  const resolve = router.resolve.bind(router)
  router.resolve = (to, current, append) => {
    if (typeof to === 'string') {
      to = normalizeURL(to)
    }
    return resolve(to, current, append)
  }

  return router
}
