import axios from 'axios'

export default async function (): Promise<void> {
  try {
    // revision.jsonファイルから最新のバージョンを取得
    const res = await axios.get('/version.json')
    const data = res.data
    const latestVersion = data.version

    // 環境変数から現在のバージョンを取得
    const curVersion = process.env.CIRCLE_BUILD_NUM ? Number(process.env.CIRCLE_BUILD_NUM) : 0

    // 最新バージョンよりも古かったら、強制的にリロードする
    if (curVersion < latestVersion) { location.reload(true) }
  } catch (error) {
    console.error(error)
    // エラーのときはなにもしない
  }
}
